import React from "react";

function PrivacyPolicy() {
    return (
        <div>
            <br />
            <p>Zuletzt aktualisiert am: 24.08.2024</p>
            <br />
            <p>
                Wir legen grossen Wert darauf, dass der Umgang mit Personendaten
                transparent ist. Diese Datenschutzerklärung gibt Auskunft darüber,
                welche personenbezogenen Daten wir sammeln, zu welchem Zweck und an wen
                wir sie weitergeben. Um eine hohe Transparenz zu gewährleisten, wird
                diese Datenschutzerklärung regelmässig überprüft und aktualisiert.{" "}
            </p>
            <br />
            <h2>1. Welche Dienste wir nutzen</h2>
            <p></p>
            <ul>
                <li>
                    <span>Google AdSense</span>
                </li>
                <li>
                    <span>Google Ads</span>
                </li>
                <li>
                    <span>Google Ads Conversion Tracking</span>
                </li>
                <li>
                    <span>Google Publisher Tag</span>
                </li>
                <li>
                    <span>Cloudflare Browser Insights</span>
                </li>
                <li>
                    <span>Google Analytics</span>
                </li>
                <li>
                    <span>Google Cloud Trace</span>
                </li>
                <li>
                    <span>Google Tag Manager</span>
                </li>
                <li>
                    <span>Cloudflare CDN</span>
                </li>
                <li>
                    <span>Cloudflare Stream</span>
                </li>
                <li>
                    <span>DigitalOcean Spaces</span>
                </li>
                <li>
                    <span>Google Cloud CDN</span>
                </li>
                <li>
                    <span>Google Cloud Storage</span>
                </li>
                <li>
                    <span>Google Fonts API</span>
                </li>
                <li>
                    <span>Google Hosted Libraries</span>
                </li>
                <li>
                    <span>Google Sign-in</span>
                </li>
                <li>
                    <span>Google reCaptcha</span>
                </li>
                <li>
                    <span>Firebase</span>
                </li>
                <li>
                    <span>Google Cloud</span>
                </li>
            </ul>
            <p></p>
            <br />
            <h2>2. Kontaktinformationen</h2>
            <p>
                Bei Fragen oder Anliegen zum Schutz Ihrer Daten durch uns erreichen Sie
                uns jederzeit per E-Mail unter luis@noten-rechner.ch. Verantwortlich für
                die Datenbearbeitungen, die über diese Website erfolgen, ist:
            </p>
            <br />
            <p>
                Luis Sascha Hutterli
                <br />
                Bärenstrasse 24
                <br />
                8280 Kreuzlingen
                <br />
                Schweiz
                <br />
                <br />
                <strong>Datenschutzverantwortliche Person:</strong>
                <br />
                Luis Sascha Hutterli
                <br />
                luis@noten-rechner.ch
                <br />
                +41 79 874 04 48
            </p>
            <br />
            <h2>3. Allgemeine Grundsätze</h2>
            <h3>
                3.1 Welche Daten sammeln wir von Ihnen und von wem erhalten wir diese
                Daten
            </h3>
            <p>
                In erster Linie bearbeiten wir Personendaten, die Sie uns übermitteln
                oder die wir beim Betrieb unserer Website sammeln. Unter Umständen
                erhalten wir Personendaten über Sie auch von Dritten. Das können
                folgende Kategorien sein:
            </p>
            <ul>
                <li>Personenstammdaten (Name, Adresse, Geburtsdaten, etc.);</li>
                <li>Kontaktdaten (Handynummer, E-Mailadresse, etc.);</li>
                <li>Finanzdaten (bspw. Kontoangaben);</li>
                <li>Onlinekennungen (bspw. Cookie-Kennung, IP-Adressen);</li>
                <li>Standort- und Verkehrsdaten;</li>
                <li>Ton- und Bildaufnahmen;</li>
                <li>
                    besonders schützenswerte Daten (bspw. biometrische Daten oder Angaben
                    über Ihre Gesundheit).
                </li>
            </ul>
            <br />
            <h3>3.2 Unter welchen Voraussetzungen bearbeiten wir Ihre Daten?</h3>
            <p>
                Wir behandeln Ihre Daten vertraulich und gemäss den in dieser
                Datenschutzerklärung festgelegten Zwecken. Wir achten dabei auf eine
                transparente und verhältnismässige Bearbeitung.
                <br />
                <br />
                Falls wir ausnahmsweise nicht in der Lage sind, diese Grundsätze zu
                befolgen, kann die Datenbearbeitung trotzdem rechtmässig sein, weil ein
                Rechtfertigungsgrund vorliegt. Als Rechtfertigungsgrund kommt namentlich
                in Frage:
            </p>
            <ul>
                <li>Ihre Einwilligung;</li>
                <li>
                    die Durchführung eines Vertrages oder vorvertraglicher Massnahmen;
                </li>
                <li>
                    unsere berechtigten Interessen, sofern Ihre Interessen nicht
                    überwiegen.
                </li>
            </ul>
            <br />
            <h3>3.3 Wie können Sie Ihre Einwilligung widerrufen?</h3>
            <p>
                Haben Sie uns eine Einwilligung zur Bearbeitung Ihrer personenbezogenen
                Daten für bestimmte Zwecke erteilt, bearbeiten wir Ihre Daten im Rahmen
                dieser Einwilligung, soweit wir keinen anderen Rechtfertigungsgrund
                haben.
                <br />
                <br />
                Sie haben jederzeit die Möglichkeit, Ihre Einwilligung zu widerrufen
                indem Sie eine E-Mail an die im Impressum genannte Adresse schicken.
                Bereits erfolgte Datenverarbeitungen sind davon nicht betroffen.
            </p>
            <br />
            <h3>
                3.4 In welchen Fällen können wir Ihre Daten an Dritte weitergeben?
            </h3>
            <br />
            <h4>a. Grundsatz</h4>
            <p>
                Wir sind unter Umständen darauf angewiesen, die Dienste Dritter oder von
                verbundenen Unternehmen in Anspruch zu nehmen und diese mit der
                Verarbeitung Ihrer Daten zu beauftragen (sog. Auftragsverarbeiter).
                Kategorien der Empfänger sind namentlich:
            </p>
            <ul>
                <li>Buchhaltung, Treuhand und Revisionsunternehmen;</li>
                <li>Beratungsunternehmen (Rechtsberatung, Steuern, etc.);</li>
                <li>
                    IT-Dienstleister (Webhosting, Support, Clouddienste,
                    Webseitengestaltung, etc.);
                </li>
                <li>Zahlungsdienstleister;</li>
                <li>Anbieter von Tracking-, Conversion- und Werbedienstleistungen.</li>
            </ul>
            <br />
            <p>
                Wir stellen sicher, dass diese Dritten und unsere verbundenen
                Unternehmen die Voraussetzungen des Datenschutzes einhalten und Ihre
                personenbezogenen Daten vertraulich behandeln.
                <br />
                <br />
                Unter Umständen sind wir auch verpflichtet, Ihre personenbezogenen Daten
                an Behörden bekanntzugeben.
            </p>
            <br />
            <h4>b. Besuch unserer Social-Media-Kanäle</h4>
            <p>
                Wir haben eventuell auf unserer Website Links zu unseren
                Social-Media-Kanälen eingebettet. Das ist für Sie jeweils ersichtlich
                (typischerweise über entsprechende Symbole). Klicken Sie auf die
                Symbole, werden Sie auf unsere Social-Media-Kanäle weitergeleitet.
                <br />
                <br />
                Die Social Media Anbieter erfahren in diesem Fall, dass Sie von unserer
                Website auf deren Plattform zugreifen. Die Social Media Anbieter können
                die so erhobenen Daten für eigene Zwecke nutzen. Wir weisen darauf hin,
                dass wir keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
                Nutzung durch die Betreiber erhalten.
            </p>
            <br />
            <h4>c. Weitergabe ins Ausland</h4>
            <p>
                Unter Umständen kann es im Rahmen der Auftragsbearbeitung zu
                Übermittlung Ihrer personenbezogenen Daten an Unternehmen im Ausland
                kommen. Diese Unternehmen sind im gleichen Umfang zum Datenschutz
                verpflichtet, wie wir selbst. Die Übermittlung kann weltweit
                stattfinden.
                <br />
                <br />
                Entspricht das Datenschutzniveau nicht demjenigen des der Schweiz, so
                nehmen wir eine vorgängige Risikoeinschätzung vor und stellen
                vertraglich sicher, dass der gleiche Schutz wie in der Schweiz
                garantiert wird (bspw. mittels der neuen Standardvertragsklauseln der
                EU-Kommission oder anderen, gesetzlich vorgegebenen Massnahmen). Sollte
                unsere Risikoeinschätzung negativ ausfallen, ergreifen wir zusätzliche
                technische Massnahmen zum Schutz Ihrer Daten. Sie können die
                Standardvertragsklauseln der EU-Kommission abrufen unter folgendem Link.
                https://commission.europa.eu/publications/standard-contractual-clauses-controllers-and-processors-eueea_de
            </p>
            <br />
            <h3>3.5 Wie lange bewahren wir Ihre Daten auf?</h3>
            <p>
                Wir speichern personenbezogene Daten nur so lange, wie dies erforderlich
                ist, um die einzelnen Zwecke, zu denen die Daten erhoben wurden, zu
                erfüllen.
                <br />
                <br />
                Daten, die wir bei Ihrem Besuch auf unserer Website speichern, werden
                während zwölf Monaten aufbewahrt. Eine Ausnahme gilt für Analyse- und
                Trackingdaten, die länger aufbewahrt werden können.
                <br />
                <br />
                Vertragsdaten speichern wir länger, da wir dazu durch gesetzliche
                Vorschriften verpflichtet sind. Wir müssen insbesondere geschäftliche
                Kommunikation, geschlossene Verträge und Buchungsbelege bis zu 10 Jahren
                aufbewahren. Soweit wir solche Daten von Ihnen nicht mehr zur
                Durchführung der Dienstleistungen benötigen, werden die Daten gesperrt
                und wir verwenden sie nur noch für Zwecke der Rechnungslegung und für
                Steuerzwecke.
            </p>
            <br />
            <h3>3.6 Wie schützen wir Ihre Daten?</h3>
            <p>
                Wir werden Ihre Daten sicher aufbewahren und alle angemessenen
                Massnahmen ergreifen, um Ihre Daten vor Verlust, Zugriff, Missbrauch
                oder Änderungen zu schützen.
                <br />
                <br />
                Unsere Vertragspartner und Mitarbeitende, die Zugang zu Ihren Daten
                haben, sind zur Einhaltung der datenschutzrechtlichen Bestimmungen
                verpflichtet. In manchen Fällen wird es erforderlich sein, dass wir Ihre
                Anfragen an mit uns verbundene Unternehmen weiterreichen. Auch in diesen
                Fällen werden Ihre Daten vertraulich behandelt.
                <br />
                <br />
                Innerhalb unserer Webseite verwenden wir das SSL-Verfahren (Secure
                Socket Layer) in Verbindung mit der jeweils höchsten
                Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird.
            </p>
            <br />
            <h3>3.7 Welche Rechte haben Sie?</h3>
            <br />
            <h4>a. Auskunftsrecht</h4>
            <p>
                Sie können jederzeit eine Auskunft über die von uns über Sie
                gespeicherten Daten verlangen. Wir bitten Sie, Ihr Auskunftsgesuch
                zusammen mit einem Identitätsnachweis an luis@noten-rechner.ch zu senden.
                <br />
                <br />
                Sie haben ausserdem das Recht, Ihre Daten in einem gängigen Dateiformat
                zu erhalten, wenn wir Ihre Daten automatisiert bearbeiten, und wenn:
            </p>
            <ul>
                <li>
                    Sie Ihre Einwilligung für die Bearbeitung dieser Daten erteilt haben;
                    oder
                </li>
                <li>
                    Sie Daten im Zusammenhang mit dem Abschluss oder der Abwicklung eines
                    Vertrags bekannt gegeben haben.
                </li>
            </ul>
            <p>
                Wir können die Auskunft bzw. Datenherausgabe einschränken oder
                verweigern, wenn dies unseren gesetzlichen Verpflichtungen, berechtigten
                eigenen oder öffentlichen Interessen oder Interessen einer Drittperson
                entgegensteht.
                <br />
                <br />
                Die Bearbeitung Ihres Gesuchs unterliegt der gesetzlichen
                Bearbeitungsfrist von 30 Tagen. Diese Frist dürfen wir jedoch aufgrund
                von hohem Anfragevolumen, aus rechtlichen oder technischen Gründen oder
                weil wir nähere Angaben von Ihnen benötigen, verlängern. Sie werden über
                die Fristverlängerung rechtzeitig mindestens in Textform informiert.
            </p>
            <br />
            <h4>b. Löschung und Berichtigung</h4>
            <br />
            <p>
                Sie haben jederzeit die Möglichkeit, die Löschung oder Berichtigung
                Ihrer Daten zu verlangen. Wir können das Gesuch abweisen, wenn
                gesetzliche Vorschriften uns zur längeren bzw. unveränderten
                Aufbewahrung verpflichten oder ein Erlaubnistatbestand Ihrem Gesuch
                entgegensteht.
                <br />
                <br />
                Bitte beachten Sie, dass die Ausübung Ihrer Rechte unter Umständen im
                Konflikt mit vertraglichen Abmachungen stehen und entsprechende
                Auswirkungen auf die Vertragsdurchführung haben kann (z.B. vorzeitige
                Vertragsauflösung oder Kostenfolgen).
            </p>
            <br />
            <h4>c. Rechtsweg</h4>
            <p>
                Sind Sie von der Bearbeitung personenbezogener Daten betroffen, haben
                Sie das Recht, Ihre Rechte gerichtlich durchzusetzen oder bei der
                zuständigen Aufsichtsbehörde eine Meldung einzureichen. Die zuständige
                Aufsichtsbehörde in der Schweiz ist der Eidgenössische Datenschutz- und
                Öffentlichkeitsbeauftragte: https://www.edoeb.admin.ch
            </p>
            <br />
            <h3>3.8 Änderungen an der Datenschutzerklärung</h3>
            <p>
                Wir können diese Datenschutzerklärung jederzeit ändern. Die Änderungen
                werden auf noten-rechner.ch veröffentlicht, Sie werden nicht gesondert
                darüber informiert.
            </p>
            <br />
            <h2>4. Einzelne Datenverarbeitungsvorgänge</h2>
            <br />
            <h3>4.1 Bereitstellen der Webseite und Erstellung von Logfiles</h3>
            <br />
            <h4>Welche Informationen erhalten wir und wie nutzen wir sie?</h4>
            <p>
                Indem Sie noten-rechner.ch besuchen, werden auf unseren Servern oder auf
                Servern von Dienstleistungen und Produkten, die wir beziehen und / oder
                installiert haben, bestimmte Daten automatisch zu Zwecken der
                Systemadministration, für statistische oder für Sicherungszwecke oder
                für Trackingzwecke gespeichert. Es handelt sich dabei um:
            </p>
            <ul>
                <li>den Namen Ihres Internetserviceproviders;</li>
                <li>Ihre IP-Adresse (unter Umständen);</li>
                <li>die Version Ihrer Browser-Software;</li>
                <li>
                    das Betriebssystem des Rechners mit dem auf die URL zugegriffen wird;
                </li>
                <li>das Datum und die Uhrzeit des Zugriffs;</li>
                <li>die Webseite, von der aus Sie URL besuchen;</li>
                <li>die Suchwörter die Sie benutzt haben, um die URL finden.</li>
            </ul>
            <br />
            <h4>Weshalb dürfen wir diese Daten bearbeiten?</h4>
            <p>
                Diese Daten können keiner bestimmten Person zugeordnet werden und es
                findet keine Zusammenführung dieser Daten mit anderen Datenquellen
                statt. Die Speicherung der Logfiles erfolgt, um die Funktionsfähigkeit
                der Webseite zu garantieren und zur Sicherstellung der Sicherheit
                unserer informationstechnischen Systeme. Hierin besteht unser
                berechtigtes Interesse.
            </p>
            <br />
            <h4>Wie können Sie die Datenerfassung verhindern?</h4>
            <p>
                Die Daten werden nur solange gespeichert, wie dies zur Erreichung des
                Zweckes Ihrer Erhebung notwendig ist. Dementsprechend werden die Daten
                nach Beendigung jeder Sitzung gelöscht. Die Speicherung der Logfiles ist
                für den Betrieb der Webseite zwingend notwendig, Sie haben daher keine
                Möglichkeit dagegen Widerspruch zu erheben.
            </p>
            <br /> <br />
            <br />
            <h3>4.2 Google AdSense</h3>
            <p>
                Auf unserer Website nutzen wir Google AdSense, einen Dienst zum
                Einbinden von Werbeanzeigen der Google LLC (1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA; "Google").
                <br />
                <br />
                Google AdSense verwendet Cookies, um die Nutzung der Website durch den
                Besucher zu analysieren. Darüber hinaus verwendet Google AdSense auch
                sogenannte Web Beacons (unsichtbare Grafiken). Durch diese Web Beacons
                können Informationen wie der Besucherverkehr auf den Seiten dieses
                Angebots ausgewertet werden.
                <br />
                <br />
                Die durch Cookies und Web Beacons erzeugten Informationen über die
                Benutzung dieser Website (einschliesslich der IP-Adresse der Nutzer) und
                Auslieferung von Werbeformaten werden an einen Server von Google in den
                USA übertragen und dort gespeichert. Diese Informationen können von
                Google an Vertragspartner von Google weitergegeben werden. Google wird
                Ihre IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten Daten
                zusammenführen.
                <br />
                <br />
                Sie können die Installation der Cookies durch eine entsprechende
                Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
                darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen unserer Website vollumfänglich nutzen können.
                <br />
                <br />
                Des Weiteren können Sie interessenbezogene Anzeigen bei Google sowie
                interessenbezogene Google Anzeigen im Web (innerhalb des Google
                Displaynetzwerks) in Ihrem Browser deaktivieren, indem Sie unter
                https://www.google.com/settings/ads die Schaltfläche "Aus" aktivieren
                oder die Deaktivierung unter https://www.aboutads.info/choices/
                vornehmen. Weitere Informationen zu Ihren Einstellmöglichkeiten und
                Datenschutz bei Google finden Sie unter
                https://www.google.com/intl/de/policies/privacy/?fg=1.
            </p>
            <br />
            <br />
            <h3>4.3 Google Ads</h3>
            <p>
                Wir nutzen auf unserer Webseite den Dienst Google Ads, einen
                Online-Werbedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain
                View, CA 94043, USA (nachfolgend "Google"). Google Ads ermöglicht es
                uns, Werbeanzeigen in den Google-Suchergebnissen sowie im
                Google-Werbenetzwerk zu schalten.
            </p>
            <br />
            <br />
            <h3>4.4 Google Ads Conversion Tracking</h3>
            <p>
                Wir verwenden auf unserer Website das Online-Werbeprogramm "Google Ads"
                und in diesem Rahmen das Conversion Tracking (Besuchsaktionsauswertung).
                Das Google Ads Conversion Tracking ist ein Analysedienst der Google LLC
                (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google").
                <br />
                <br />
                Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein
                Cookie für das Conversion Tracking auf Ihrem Endgerät gesetzt. Diese
                Cookies haben eine begrenzte Gültigkeit, enthalten keine
                personenbezogenen Daten und dienen somit nicht der persönlichen
                Identifizierung. Wenn Sie bestimmte Seiten unserer Website besuchen und
                das Cookie noch nicht abgelaufen ist, können Google und wir erkennen,
                dass Sie auf die Anzeige geklickt haben und zu dieser Seite
                weitergeleitet wurden.
                <br />
                <br />
                Die Informationen, die mithilfe des Conversion-Cookies eingeholt werden,
                dienen dazu, Conversion-Statistiken zu erstellen. Hierbei erfahren wir
                die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen geklickt
                haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
                weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit
                denen sich Nutzer persönlich identifizieren lassen.
                <br />
                <br />
                Sie können dazu die Speicherung der Cookies durch die Auswahl
                entsprechender technischer Einstellungen Ihrer Browser-Software
                verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
                nutzen können. Sie werden dann nicht in die Conversion-Tracking
                Statistiken aufgenommen.
                <br />
                <br />
                Des Weiteren können Sie in den Einstellungen für Werbung bei Google für
                Sie personalisierte Werbung deaktivieren. Eine Anleitung dazu finden Sie
                unter https://support.google.com/My-Ad-Center-Help/answer/12155764.
            </p>
            <br />
            <br />
            <h3>4.5 Google Publisher Tag</h3>
            <p>
                Auf unserer Website verwenden wir den Google Publisher Tag, einen Dienst
                der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                "Google"). Der Google Publisher Tag ermöglicht es uns, Werbung auf
                unserer Website effizient zu verwalten und zu optimieren.
                <br />
                <br />
                Durch die Einbindung des Google Publisher Tags werden Cookies und
                ähnliche Technologien auf Ihrem Endgerät gespeichert und Informationen
                über die Nutzung unserer Website, einschliesslich Ihrer IP-Adresse, an
                Google übermittelt. Diese Informationen können von Google zur Auswertung
                Ihrer Nutzung der Website, zur Erstellung von Berichten über die
                Websiteaktivitäten und zur Erbringung weiterer mit der Websitenutzung
                und der Internetnutzung verbundener Dienstleistungen gegenüber uns
                verwendet werden.
                <br />
                <br />
                Wenn Sie keine zielgerichtete Werbung erhalten möchten, können Sie die
                Speicherung von Cookies durch eine entsprechende Einstellung Ihrer
                Browser-Software verhindern. Bitte beachten Sie jedoch, dass Sie in
                diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
                vollumfänglich nutzen können.
            </p>
            <br />
            <br />
            <h3>4.6 Cloudflare Browser Insights</h3>
            <p>
                Unsere Website verwendet den Dienst "Cloudflare Browser Insights" von
                Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA
                ("Cloudflare"). Dieser Dienst sammelt und analysiert Daten über die
                Performance und Nutzung unserer Website aus der Perspektive des
                Endbenutzers.
                <br />
                <br />
                Bei Ihrem Besuch auf unserer Website werden verschiedene Daten,
                einschliesslich Ihrer IP-Adresse, Informationen über den von Ihnen
                genutzten Browser, Ladezeiten der Webseite, Interaktionen auf der Seite
                und andere technische Informationen, an Cloudflare übermittelt. Diese
                Daten werden von Cloudflare genutzt, um uns detaillierte Berichte über
                die Performance unserer Website und das Nutzerverhalten zur Verfügung zu
                stellen.
            </p>
            <br />
            <br />
            <h3>4.7 Google Analytics</h3>
            <p>
                Wir verwenden auf unserer Website Google Analytics, einen
                Webanalysedienst der Google LLC (1600 Amphitheatre Parkway, Mountain
                View, CA 94043, USA; "Google"). Google Analytics verwendet sogenannte
                "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und
                die eine Analyse der Benutzung der Website durch Sie ermöglichen.
                <br />
                <br />
                Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser
                Website werden in der Regel an einen Server von Google in den USA
                übertragen und dort gespeichert. Im Falle der Aktivierung der
                IP-Anonymisierung auf dieser Website wird Ihre IP-Adresse von Google
                jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in
                anderen Vertragsstaaten des Abkommens über den Europäischen
                Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
                IP-Adresse an einen Server von Google in den USA übertragen und dort
                gekürzt.
                <br />
                <br />
                Im Auftrag des Betreibers dieser Website wird Google diese Informationen
                benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
                Websiteaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene Dienstleistungen
                gegenüber dem Websitebetreiber zu erbringen.
                <br />
                <br />
                Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
                IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
                <br />
                <br />
                Sie können die Speicherung der Cookies durch eine entsprechende
                Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
                darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website vollumfänglich werden nutzen können. Sie
                können darüber hinaus die Erfassung der durch das Cookie erzeugten und
                auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
                Google sowie die Verarbeitung dieser Daten durch Google verhindern,
                indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
                herunterladen und installieren:
                https://tools.google.com/dlpage/gaoptout.
            </p>
            <br />
            <br />
            <h3>4.8 Google Cloud Trace</h3>
            <p>
                Zur Überwachung, Analyse und Optimierung der Performance unserer
                Anwendungen nutzen wir Google Cloud Trace, einen Dienst der Google LLC
                (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google").
                Google Cloud Trace ermöglicht es uns, detaillierte Informationen über
                die Latenz und Gesundheit unserer Anwendungen zu sammeln, um eventuelle
                Engpässe oder Probleme zu identifizieren und zu beheben.
                <br />
                <br />
                Bei der Nutzung von Google Cloud Trace werden technische Daten und
                Informationen über die Ausführung unserer Anwendungen, einschliesslich
                Ihrer IP-Adresse und anderer relevanter Metriken, an Google übermittelt
                und auf Servern von Google gespeichert. Diese Server können sich in den
                USA oder anderen Ländern befinden.
                <br />
                <br />
                Google verwendet diese Informationen, um uns detaillierte Berichte über
                die Performance und das Verhalten unserer Anwendungen zur Verfügung zu
                stellen und uns bei der Identifizierung und Behebung von Problemen zu
                unterstützen.
                <br />
                <br />
                Weitere Informationen über die Datenerfassungs- und Datenschutzpraktiken
                von Google in Bezug auf Google Cloud Trace finden Sie in der
                Datenschutzerklärung von Google unter
                https://www.google.de/intl/de/policies/privacy/ und in den spezifischen
                Informationen zu Google Cloud Trace unter
                https://cloud.google.com/trace/docs.
            </p>
            <br />
            <br />
            <h3>4.9 Google Tag Manager</h3>
            <p>
                Für die Verwaltung von Website-Tags über eine Oberfläche nutzen wir den
                Google Tag Manager, einen Dienst der Google LLC (1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA; "Google"). Der Google Tag Manager
                selbst (welcher die Tags implementiert) ist eine cookie-lose Domain und
                verarbeitet keine personenbezogenen Daten. Der Dienst sorgt für die
                Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen.
                Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain-
                oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für
                alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert
                werden.
                <br />
                <br />
                Durch die Nutzung des Google Tag Managers können automatisiert Skripte
                (sogenannte Tags) auf unserer Website aktiviert und Daten von Ihrem
                Browser an Google übermittelt werden. Diese Daten können Informationen
                über Ihre IP-Adresse, den von Ihnen genutzten Browser, die besuchten
                Unterseiten unserer Website und weitere Interaktionen während Ihres
                Website-Besuchs beinhalten.
            </p>
            <br />
            <br />
            <h3>4.10 Cloudflare CDN</h3>
            <p>
                Unsere Website nutzt den Content Delivery Network (CDN) Dienst von
                Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA
                ("Cloudflare"). Ein CDN hilft, Inhalte unserer Website schneller zu
                liefern, indem es Kopien der Inhalte an ein Netzwerk von schnellen
                Lade-Servern verteilt.
                <br />
                <br />
                Wenn Sie unsere Website besuchen, wird Ihre IP-Adresse an Cloudflare
                übermittelt und es können Cookies in Ihrem Browser gesetzt werden, die
                für die Optimierung und Sicherheit des Dienstes erforderlich sind.
                Cloudflare verwendet diese Daten, um den Datenverkehr zwischen unserem
                Webserver und Ihrem Endgerät zu beschleunigen und um unsere Website vor
                Angriffen zu schützen.
            </p>
            <br />
            <br />
            <h3>4.11 Cloudflare Stream</h3>
            <p>
                Unsere Website verwendet den Dienst "Cloudflare Stream" von Cloudflare,
                Inc., 101 Townsend St, San Francisco, CA 94107, USA ("Cloudflare").
                Cloudflare Stream ist eine Videostreaming-Plattform, die es uns
                ermöglicht, Videos effizient zu hosten, zu streamen und zu
                monetarisieren.
                <br />
                <br />
                Wenn Sie Videos auf unserer Website ansehen, die über Cloudflare Stream
                bereitgestellt werden, werden verschiedene Daten, einschliesslich Ihrer
                IP-Adresse, Informationen über den von Ihnen genutzten Browser, Datum
                und Uhrzeit des Zugriffs sowie Interaktionsdaten mit dem Video, an
                Cloudflare übermittelt und dort gespeichert. Diese Daten werden
                verwendet, um das Video-Streaming zu ermöglichen, die Performance zu
                überwachen und das Nutzererlebnis zu verbessern.
            </p>
            <br />
            <br />
            <h3>4.12 DigitalOcean Spaces</h3>
            <p>
                DigitalOcean Spaces ist ein Service von DigitalOcean, LLC, 101 Avenue of
                the Americas, 10th Floor, New York, NY 10013, USA. DigitalOcean Spaces
                ist ein skalierbarer und sicherer Object Storage-Service, mit dem
                Unternehmen Daten speichern und abrufen können.
                <br />
                <br />
                Wir nutzen DigitalOcean Spaces, um verschiedene Arten von Daten, wie
                Bilder, Dokumente und andere Mediendateien, zu speichern und
                bereitzustellen. Dies ermöglicht uns, eine schnelle und zuverlässige
                Performance unserer Website zu gewährleisten und grosse Mengen an Daten
                effizient zu verwalten.
                <br />
                <br />
                Zur Speicherung und Abrufung von Daten verwendet DigitalOcean Spaces
                verschiedene Technologien, darunter Cookies und Serverprotokolle. Diese
                Technologien können Informationen wie IP-Adressen, Datum und Uhrzeit des
                Zugriffs, Dateityp und -grösse sowie andere technische Informationen
                erfassen.
                <br />
                <br />
                Es ist zu beachten, dass Daten, die in DigitalOcean Spaces gespeichert
                sind, in Rechenzentren in verschiedenen Regionen weltweit gespeichert
                werden können. DigitalOcean hat jedoch Massnahmen ergriffen, um
                sicherzustellen, dass die Daten sicher und gemäss geltenden
                Datenschutzstandards gespeichert werden.
            </p>
            <br />
            <br />
            <h3>4.13 Google Cloud CDN</h3>
            <p>
                Für die Optimierung der Auslieferung unserer Webinhalte und die
                Beschleunigung des Zugriffs für unsere Nutzer setzen wir Google Cloud
                CDN (Content Delivery Network) ein, einen Dienst der Google LLC (1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). Google
                Cloud CDN verwendet weltweit verteilte Server, um unsere Webinhalte
                schneller an Endnutzer zu liefern, indem Inhalte von dem Server
                ausgeliefert werden, der geografisch am nächsten zum Nutzer liegt.
                <br />
                <br />
                Bei der Nutzung von Google Cloud CDN werden Nutzungsdaten,
                einschliesslich Ihrer IP-Adresse, an Google übermittelt und können auf
                Servern von Google gespeichert werden. Diese Server können sich in den
                USA oder anderen Ländern befinden.
                <br />
                <br />
                Weitere Informationen über die Datenerfassungs- und Datenschutzpraktiken
                von Google in Bezug auf Google Cloud CDN finden Sie in der
                Datenschutzerklärung von Google unter
                https://www.google.de/intl/de/policies/privacy/ und in den spezifischen
                Informationen zu Google Cloud CDN unter
                https://cloud.google.com/cdn/docs.
            </p>
            <br />
            <br />
            <h3>4.14 Google Cloud Storage</h3>
            <p>
                Für die Speicherung von Daten nutzen wir Google Cloud Storage, einen
                Cloud-Speicherdienst der Google LLC (1600 Amphitheatre Parkway, Mountain
                View, CA 94043, USA; "Google"). Google Cloud Storage ermöglicht es uns,
                Daten sicher in der Cloud zu speichern und bei Bedarf abzurufen.
                <br />
                <br />
                Durch die Nutzung von Google Cloud Storage werden Daten und
                Informationen, die durch die Nutzung unserer Dienste generiert oder von
                uns hochgeladen werden, an Google übermittelt und auf Servern von Google
                gespeichert. Diese Server können sich in den USA oder anderen Ländern
                befinden.
                <br />
                <br />
                Google verwendet diese Informationen, um den sicheren Speicher und die
                schnelle Verfügbarkeit unserer Daten in der Cloud zu gewährleisten.
                Dabei werden die Daten verschlüsselt und in einem für uns dedizierten
                Bereich gespeichert.
                <br />
                <br />
                Sie können weitere Informationen über die Datenerfassungs- und
                Datenschutzpraktiken von Google in Bezug auf Google Cloud Storage in der
                Datenschutzerklärung von Google unter
                https://www.google.de/intl/de/policies/privacy/ und in den spezifischen
                Informationen zu Google Cloud Storage unter
                https://cloud.google.com/storage/docs/ finden.
            </p>
            <br />
            <br />
            <h3>4.15 Google Fonts API</h3>
            <p>
                Auf unserer Website nutzen wir die Google Fonts API, einen Dienst der
                Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                "Google"), um Schriftarten (Fonts) einzubinden. Durch die Einbindung
                dieser Schriftarten wird Ihr Browser beim Aufrufen unserer Website eine
                Verbindung zu den Servern von Google herstellen, um die Schriftarten
                herunterzuladen und korrekt anzuzeigen.
                <br />
                <br />
                Durch diese Verbindung können Daten, insbesondere Ihre IP-Adresse und
                Informationen über den von Ihnen genutzten Browser, an Google
                übermittelt und auf Servern von Google gespeichert werden. Diese Server
                können sich in den USA oder anderen Ländern befinden.
                <br />
                <br />
                Wenn Sie die Übertragung von Daten im Rahmen der Google Fonts API
                verhindern möchten, können Sie in Ihren Browsereinstellungen den Zugriff
                auf die Domain fonts.googleapis.com blockieren. Bitte beachten Sie, dass
                in diesem Fall unsere Website möglicherweise nicht korrekt angezeigt
                wird.
            </p>
            <br />
            <br />
            <h3>4.16 Google Hosted Libraries</h3>
            <p>
                Auf unserer Website nutzen wir Google Hosted Libraries, einen Dienst der
                Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                "Google"). Google Hosted Libraries ermöglicht es uns, bestimmte
                Bibliotheken (z.B. JavaScript-Bibliotheken) direkt von den Servern von
                Google zu laden, um die Ladezeiten unserer Webseiten zu optimieren und
                eine konsistente Performance zu gewährleisten.
                <br />
                <br />
                Durch die Nutzung von Google Hosted Libraries wird Ihr Browser beim
                Aufrufen unserer Website eine Verbindung zu den Servern von Google
                herstellen, um die benötigten Bibliotheken herunterzuladen. Dabei können
                Daten, insbesondere Ihre IP-Adresse und Informationen über den von Ihnen
                genutzten Browser, an Google übermittelt und auf Servern von Google
                gespeichert werden. Diese Server können sich in den USA oder anderen
                Ländern befinden.
                <br />
                <br />
                Wenn Sie die Datenübertragung im Rahmen der Google Hosted Libraries
                verhindern möchten, können Sie in Ihren Browsereinstellungen den Zugriff
                auf die Domain ajax.googleapis.com blockieren. Bitte beachten Sie, dass
                in diesem Fall unsere Website möglicherweise nicht korrekt oder nur mit
                verlängerter Ladezeit angezeigt wird.
            </p>
            <br />
            <br />
            <h3>4.17 Google Sign-in</h3>
            <p>
                Auf unserer Website bieten wir die Möglichkeit an, sich über Google
                Sign-in anzumelden, einen Dienst der Google LLC (1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA; "Google"). Mit Google Sign-in
                können Sie sich auf unserer Website anmelden, indem Sie Ihr Google-Konto
                verwenden, ohne einen separaten Benutzernamen und ein separates Passwort
                für unsere Website erstellen zu müssen.
                <br />
                <br />
                Wenn Sie sich über Google Sign-in anmelden, fordert Google Sie auf,
                bestimmte Berechtigungen zu gewähren, die es uns ermöglichen, auf
                ausgewählte Informationen in Ihrem Google-Konto zuzugreifen. Dies kann
                Ihren Namen, Ihre E-Mail-Adresse und Ihr Profilbild umfassen. Diese
                Informationen werden verwendet, um Ihr Benutzerprofil auf unserer
                Website zu erstellen oder zu aktualisieren.
                <br />
                <br />
                Durch die Nutzung von Google Sign-in werden Daten, einschliesslich der
                von Ihnen gewährten Berechtigungen und Ihrer IP-Adresse, an Google
                übermittelt und auf Servern von Google gespeichert. Diese Server können
                sich in den USA oder anderen Ländern befinden.
                <br />
                <br />
                Wenn Sie nicht möchten, dass Google die über unseren Webservice
                gesammelten Daten unmittelbar Ihrem Google-Konto zuordnet, können sie
                sich nicht auf unserer Website mit Google Sign-in anmelden.
            </p>
            <br />
            <br />
            <h3>4.18 Google reCaptcha</h3>
            <p>
                Zum Schutz von ungewünschten Anfragen über das Internetformular auf
                unserer Website verwenden wir den Dienst reCAPTCHA des Unternehmens
                Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                "Google"). Die Abfrage dient der Unterscheidung, ob die Eingabe durch
                einen Menschen oder missbräuchlich durch automatisierte, maschinelle
                Verarbeitung erfolgt. Im Rahmen der Abfrage können Informationen, wie
                beispielsweise Ihre IP-Adresse oder das Verhalten beim Ausfüllen des
                Formulars, an Google übermittelt werden.
                <br />
                <br />
                Zu diesem Zweck wird Ihre Eingabe an Google übermittelt und dort
                weiterverarbeitet. Durch die Nutzung von reCAPTCHA erklären Sie sich
                damit einverstanden, dass die von Ihnen geleistete Erkennung in die
                Digitalisierung alter Werke einfliesst. Im Falle der Aktivierung der
                IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google
                jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in
                anderen Vertragsstaaten des Abkommens über den Europäischen
                Wirtschaftsraum zuvor gekürzt.
                <br />
                <br />
                Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
                Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers
                dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
                dieses Dienstes auszuwerten. Die im Rahmen von reCAPTCHA von Ihrem
                Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                zusammengeführt.
            </p>
            <br />
            <br />
            <h3>4.19 Firebase</h3>
            <p>
                Unsere Website und Anwendungen nutzen Firebase, eine Plattform für
                Entwickler, die von Google LLC (1600 Amphitheatre Parkway, Mountain
                View, CA 94043, USA; "Google") bereitgestellt wird. Firebase bietet eine
                Vielzahl von Tools und Diensten zur Entwicklung, Verwaltung und
                Verbesserung von Web- und Mobilanwendungen.
                <br />
                <br />
                Durch die Nutzung von Firebase können verschiedene Daten,
                einschliesslich Ihrer IP-Adresse, Informationen über den von Ihnen
                genutzten Browser, Datum und Uhrzeit des Zugriffs sowie weitere
                Interaktionsdaten, an Server von Google übermittelt und dort gespeichert
                werden. Diese Daten werden verwendet, um die Funktionalität unserer
                Anwendungen zu ermöglichen, die Performance zu überwachen, Fehler zu
                diagnostizieren und das Nutzererlebnis zu verbessern.
                <br />
                <br />
                Einige der spezifischen Dienste von Firebase, die wir nutzen könnten,
                sind:
            </p>
            <ul>
                <li>
                    Firebase Realtime Database: Ein Cloud-gehosteter
                    NoSQL-Datenbankdienst.
                </li>
                <li>
                    Firebase Analytics: Ein Analysetool zur Erfassung von Daten über die
                    Nutzung und das Verhalten der Anwender in der App.
                </li>
                <li>
                    Firebase Authentication: Ein Dienst, der Authentifizierungsfunktionen
                    für unsere Anwendungen bereitstellt.
                </li>
                <li>
                    Firebase Cloud Messaging: Ein Dienst für Benachrichtigungen und
                    Nachrichtenübermittlung.
                </li>
            </ul>
            <p></p>
            <br />
            <br />
            <h3>4.20 Google Cloud</h3>
            <p>
                Für Speicher-, Hosting- und Datenverarbeitungsdienste nutzen wir Google
                Cloud, eine Cloud-Plattform-Dienstleistung der Google LLC (1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). Google
                Cloud ermöglicht es uns, Daten sicher in der Cloud zu speichern, zu
                verarbeiten und von dort abzurufen.
                <br />
                <br />
                Durch die Nutzung von Google Cloud werden Daten und Informationen, die
                durch die Nutzung unserer Dienste generiert werden, einschliesslich
                Ihrer IP-Adresse und anderer relevanter Daten, an Google übermittelt und
                auf Servern von Google gespeichert. Diese Server können sich in den USA
                oder anderen Ländern befinden.
                <br />
                <br />
                Google verwendet diese Informationen, um den Betrieb und die
                Verfügbarkeit unserer Daten und Anwendungen in der Cloud zu
                gewährleisten und um uns Berichte und Analysen zur Verfügung zu stellen.
                <br />
                <br />
                Sie können weitere Informationen über die Datenerfassungs- und
                Datenschutzpraktiken von Google in Bezug auf die Google Cloud in der
                Datenschutzerklärung von Google unter
                https://www.google.de/intl/de/policies/privacy/ und in den spezifischen
                Informationen zur Google Cloud unter
                https://cloud.google.com/terms/data-processing-terms finden.
            </p>{" "}
            <br />
            <br />
            <h3>BrainBox Generatoren</h3> BrainBox Generatoren ist ein Dienst der{" "}
            <a href="https://brainbox.swiss/">BrainBox Solutions GmbH</a>
            , um alle datenschutzrelevanten Dienste auf einer Website zu erkennen und
            unter anderem damit bei der Erstellung der Datenschutzerklärung zu helfen.
            Dabei werden keine personenbezogenen Daten erfasst oder verarbeitet.
        </div>
    );
}

export default PrivacyPolicy;
